@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.gradientBackground {
  background: linear-gradient(0deg, #e1e1e1, #fff 80%, #fff);
}

.portal-font {
  font-family: "signika";
}

.transforScale {
  transform: scale(0.33);
  transform-origin: top left;
}

.clip-path {
  -webkit-clip-path: polygon(0 0, 100% 0%, 91% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0%, 91% 100%, 0% 100%);
}

.css-qvtrhg-MuiDataGrid-virtualScroller {
  min-height: 200px !important;
}

.MuiDataGrid-virtualScroller{
  min-height: 200px !important;
}

.main {
  min-height: calc(100vh - 75px);
}

.MuiDataGrid-root {
  background: rgb(255, 255, 255);
  padding: 24px;
  border-radius: 4px;
  border: 1px solid rgb(234, 234, 239);
  box-shadow: rgba(33, 33, 52, 0.1) 0px 1px 4px;
}

.glass {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.43);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.3px);
  -webkit-backdrop-filter: blur(10.3px);
  border: 1px solid rgba(255, 255, 255, 0.29);
}

.card {
  background: rgb(255, 255, 255);
  padding: 24px;
  border-radius: 4px;
  border: 1px solid rgb(234, 234, 239);
  box-shadow: rgba(33, 33, 52, 0.1) 0px 1px 4px;
}

.dot {
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

.fit-content{
  width: fit-content;
}